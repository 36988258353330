import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Box, Label, Input, Textarea, Button, Message, Spinner, Text} from 'theme-ui'

const PageCookies = props => {

  return (
    <Layout {...props}>
      <Seo title='Cookies' />
      <Divider />
      
     
      <Stack effectProps={{ effect: 'fadeInDown' }}>
      <PageTitle
          header='Política de Cookies de Cashfy'
          subheader='
         
        

<b>Fecha de última actualización:</b> 22/08/2022<br><br>

Cashfy ("nosotros", "nuestro" o "sitio web") utiliza cookies y tecnologías similares para mejorar la experiencia de los usuarios en nuestro sitio. Esta política de cookies explica cómo utilizamos estas tecnologías y cómo los usuarios pueden controlar su uso.<br><br>

<b>¿Qué son las cookies?</b><br>

Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo cuando visitas nuestro sitio web. Estos archivos contienen información que se utiliza para recordar tus preferencias y mejorar tu experiencia de navegación.<br><br>

<b>¿Cómo utilizamos las cookies?</b><br>

Utilizamos cookies por diversas razones, incluyendo:<br>

- <b>Funcionalidad:</b> Utilizamos cookies para recordar tus preferencias y proporcionarte funcionalidades personalizadas.<br>
- <b>Rendimiento y análisis:</b> Utilizamos cookies para medir y analizar cómo los usuarios interactúan con nuestro sitio web, lo que nos ayuda a mejorar su rendimiento y contenido.<br>
- <b>Publicidad:</b> Utilizamos cookies para personalizar la publicidad que ves en nuestro sitio web y en otros lugares en línea.<br><br>

<b>¿Qué tipos de cookies utilizamos?</b><br>

- <b>Cookies esenciales:</b> Estas cookies son necesarias para el funcionamiento básico del sitio y no se pueden desactivar en nuestros sistemas.<br>
- <b>Cookies de rendimiento:</b> Estas cookies nos ayudan a entender cómo los usuarios interactúan con nuestro sitio web y a mejorar su funcionamiento.<br>
- <b>Cookies de funcionalidad:</b> Estas cookies permiten que el sitio recuerde las elecciones que haces (como tu nombre de usuario) y proporciona funcionalidades mejoradas.<br>
- <b>Cookies de publicidad:</b> Estas cookies se utilizan para personalizar la publicidad que ves en nuestro sitio y en otros lugares en línea de acuerdo con tus intereses.<br><br>

<b>¿Cómo puedes controlar las cookies?</b><br>

Puedes controlar y administrar las cookies de diversas maneras:<br>

- <b>Configuración del navegador:</b> Puedes configurar tu navegador para que rechace todas o algunas cookies. Consulta la ayuda de tu navegador para obtener instrucciones específicas.<br>
- <b>Herramientas de gestión de cookies:</b> Puedes utilizar herramientas en línea de gestión de cookies que te permiten personalizar cómo se utilizan las cookies en tu dispositivo.<br><br>

Sin embargo, ten en cuenta que deshabilitar ciertas cookies puede afectar la funcionalidad de nuestro sitio web y limitar tu experiencia de navegación.<br><br>

<b>Actualizaciones de la política de cookies</b><br>

Nos reservamos el derecho de actualizar esta política de cookies en cualquier momento para reflejar cambios en nuestras prácticas de cookies. Cualquier actualización se publicará en esta página con una fecha de "última actualización" revisada.<br><br>

Si tienes alguna pregunta sobre nuestra política de cookies, por favor contáctanos a través de info@Cashfy.us.<br><br>

Gracias por utilizar Cashfy.



          '
        />
      </Stack>

    </Layout>
  )
}

export default PageCookies
